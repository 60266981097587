import Vue from 'vue'
import {
	createRouter,
	createWebHistory,
	RouteRecordRaw,
	createWebHashHistory
} from 'vue-router'
import Home from '@/view/index'
import my from '@/view/My/index'

const routes = [
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登陆'
		},
		component: () => import('@/view/login/index')
	},
	{
		path: '/',
		name: 'home',
		meta: {
			title: '首页'
		},
		component: () => import('@/view/index')
	},
	{
		path: '/my',
		name: 'my',
		meta: {
			title: '我的'
		},
		component: () => import('@/view/My/index')
	},
	{
		path: '/userlist',
		name: 'userlist',
		meta: {
			title: '用户列表'
		},
		component: () => import('@/view/userlist/index')
	},
	{
		path: '/manualsgin',
		name: 'manualsgin',
		meta: {
			title: '手动签到'
		},
		component: () => import('@/view/manualsgin/index')
	},
	{
		path: '/lookuser',
		name: 'lookuser',
		meta: {
			title: '手动签到'
		},
		component: () => import('@/view/lookuser/index')
	},
	{
		path: '/onlone',
		name: 'online',
		meta: {
			title: '线上报告'
		},
		component: () => import('@/view/online/index')
	},
	{
		path: '/Report',
		name: 'Report',
		meta: {
			title: '线上报告'
		},
		component: () => import('@/view/Report/index')
	},
	


]



const router = createRouter({
	history: createWebHashHistory(),
	routes
});
// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
export default router