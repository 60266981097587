import axios from 'axios'
import router from '@/router'
import {
  ElMessage,
  ElMessageBox 
} from 'element-plus'
//解决超过 16 位数字精度丢失问题
import JSONbig from 'json-bigint';

let token = localStorage.getItem("token")

axios.defaults.transformResponse = [
  function (data) {
    const json = JSONbig({
      storeAsString: true,
    });
    const res = json.parse(data);
    return res;
  },
];
// create an axios instance
const service = axios.create({
  baseURL: 'https://pcapi.btseducation.cn/api',
  // baseURL: 'http://192.168.1.33:8002/api',
  // "http://192.168.1.33:2002/
  // baseURL: '/dev', // url = base url + request url
  timeout: 3600000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
	  let token = localStorage.getItem("token")
	  console.log('token===',token)
    config.headers['Authorization'] = token
    config.headers['token'] = token
    // config.headers['Content-Type'] = "application/json;"
    // config.headers['Content-Type'] = "application/octet-stream"
    config.headers['Access-Control-Allow-Origin'] = "*"
    config.headers['Access-Control-Allow-Methods'] = "PUT,POST,GET,DELETE,OPTIONS"
    return config
  },
  error => {
    // do something with request error
    console.log(error, 'error') // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (response.config.responseType === 'blob') {
      return res
    }
    if (!res.code) {
      return res
    }
	console.log('res===',res)
	if(res.code == 10020 ||res.code == 10021){
	  // store.commit('changeState',false) 
	  console.log('res===',11111111)
	  // localStorage.clear()
	  // return ElMessage({
	  //   message: res.msg,
	  //   type: 'error',
	  //   duration: 10 * 1000
	  // })
	  console.log('router',router);
	  router.replace({
	      name: 'login'
	  })
	}
   else if (res.code != 0) {
      return ElMessage({
        message: res.msg,
        type: 'error',
        duration: 10 * 1000
      })
    }

    return res
  },
  error => {
    console.log(error)
    ElMessage({
      message: error.msg,
      type: 'error',
      duration: 10 * 1000
    })

    return Promise.reject(error)
  }
)

export default service
