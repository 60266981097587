import request from "@/utils/request";
export function newfileUpload(data, callback) {
    return request({
        url: '/file/upload',
        method: 'post',
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin":"*",
            "Access-Control-Allow-Methods":"PUT,POST,GET,DELETE,OPTIONS"
         },
        data,
    })
}
