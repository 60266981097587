<template>
	<div class='box' style="background-color: #F7F7F7;">
		<heads></heads>
		<div class="main">
			<div
				style="display: flex;justify-content: space-between;align-items: center;height: 80px;padding-left: 20px;padding-right: 20px;">
				<div style="color: #333333;font-size: 24px;padding-left: 20px;">
					个人信息
				</div>
				<div class="btn" @click="goback">
					返回
				</div>
			</div>
			<div style="height: 1px;background-color:#EEEEEE;"></div>
			<div class="topNav_box">
				<div class="topNav_tou">
					<div class="topNav_tou_box">
						<!-- <el-upload class="avatar-uploader"
							action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
							:show-file-list="false" :on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload">
							<img v-if="formLabelAlign.headUrl" :src="formLabelAlign.headUrl" class="avatar" />
                            <img v-else  src="@/assets/touxiang.png" class="avatar" />
						</el-upload> -->
						<el-upload class="avatar-uploader" action="#" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
							:http-request="($event) => upload($event)">
							<img v-if="formLabelAlign.headUrl" :src="formLabelAlign.headUrl" class="avatar" />
							<img v-else  src="@/assets/touxiang.png" class="avatar" />
						</el-upload>
						<div class="topNav_name">
							点击更换
						</div>
					</div>
				</div>
				<div class="info">
					<div class="name">
						{{formLabelAlign.name}}
					</div>
					<div class="phone">
						{{formLabelAlign.phone1}}
					</div>
					<div class="number">
						{{formLabelAlign.idNumber}}
					</div>
				</div>
			</div>
			<div style="width: 456px;margin-left: auto;margin-right: auto;">
				<el-form label-position="right" label-width="auto" :model="formLabelAlign" style="max-width: 400px">
					<el-form-item label="性别" required>
						<!-- <el-input class="editinput" placeholder="请输入" v-model="formLabelAlign.gender" /> -->
						 <el-select style="width: 350px;" v-model="formLabelAlign.gender" placeholder="请选择">
						    <el-option
						      v-for="item in actions"
						      :key="item.value"
						      :label="item.text"
						      :value="item.value">
						    </el-option>
						  </el-select>
					</el-form-item>
					<el-form-item label="邮寄地址" required>
						<el-input class="editinput" placeholder="请输入" v-model="formLabelAlign.mailingAddress	" />
					</el-form-item>
					<el-form-item label="邮箱" required>
						<el-input class="editinput" placeholder="请输入" v-model="formLabelAlign.email" />
					</el-form-item>
					<el-form-item label="银行卡开户行" required>
						<el-input class="editinput" placeholder="请输入" v-model="formLabelAlign.bankCardOpeningBank" />
					</el-form-item>
					<el-form-item label="银行卡号" required>
						<el-input class="editinput" placeholder="请输入" v-model="formLabelAlign.bankCardNum" />
					</el-form-item>
					<el-form-item label="工作单位" required>
						<el-input class="editinput" placeholder="请输入" v-model="formLabelAlign.companyName" />
					</el-form-item>
					<el-form-item label="介绍人" required>
						<el-input class="editinput" placeholder="请输入" v-model="formLabelAlign.introducer" />
					</el-form-item>
				</el-form>
			</div>
			<div class="btn" @click="submitUser"
				style="background-color:#0B3079;color: #fff;width: 130px;height: 40px;margin-left: auto;margin-right: auto;margin-bottom: 75px;margin-top: 40px;">
				保存
			</div>
		</div>
	</div>
</template>

<script>
	import heads from '@/components/head'
	import {userInfo,userInfoedit} from '@/api/index'
	
	import {
		newfileUpload,fileUpload
	} from '@/api/upfile'
	
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		// 组件名
		name: 'my',
		// 注册组件
		components: {
			heads,
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				actions: [{
						"text": "男",
						"value": 1
					},
					{
						"text": "女",
						"value": 0
					},
					{
						"text": "保密",
						"value": 3
					},
				
				],
				formLabelAlign: {},
				imageUrl: null,
				userid: '',
				lang: '',
				banner: [],
				dialogVisible: false,
				info: [],
				slected: 0,
				userinfo: {},
				userpoint: {},
				myInfo: {},
				GoodsList: [],
				Application: [],
				Activitys: [],
				tableData: [],
				seting: {},
				slectedInfo: {},
				titlearry: [],
				my_auth_info: {},
				from: '',
			}
		},
		// 计算属性
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数123123
		created() {
			var info = localStorage.getItem("user");
			
			console.log(info)
			
			this.formLabelAlign = JSON.parse(info);
			this.formLabelAlign.phone1 = this.getRegular(this.formLabelAlign.phone)
		},
		mounted() {},
		// 组件方法
		methods: {
			submitUser(){
				userInfoedit(this.formLabelAlign).then(res => {
					console.log(res)
					if(res.code == 0){
						ElMessage({
							message: '修改成功',
							type: 'success',
						})
						this.getdata()
					}
					
				})
			},
			getRegular(num) {
				var nums = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3")
				console.log('nums====', nums)
				return nums
			},
			getdata() {
				userInfo().then(res => {
					
					this.formLabelAlign =  res.data
					
					this.formLabelAlign.phone1 = this.getRegular(this.formLabelAlign.phone)
					
					localStorage.setItem("user", JSON.stringify(res.data))
				})
			},
			upload(e) {
				const loading = this.$loading({
					lock: true,
					text: '正在上传，请耐心等候！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				let file = e.file
				
				console.log(file)
				
				let formData = new FormData()
				// let formData = new FormData()
				// formData.append('file', file)
				formData.append('file', file)
				
				console.log('formData==',formData);
				
				  // formData.append("type", 5);
				  
				newfileUpload(formData).then((res) => {
					console.log(res, 'file')
					if (res.code == 0) {
						loading.close()
						this.formLabelAlign.headUrl = res.data.value
					}
				})
			},
			goback() {
				this.$router.push({
					name: 'home'
				})
			},
		},
	}
</script>

<style scoped lang='scss'>
	.box {
		background-color: #F7F7F7;
		/* min-height: 100vh; */

		.main {
			max-width: 1600px;
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;
			background-color: #fff;
		}

	}

	.btn {
		width: 72px;
		height: 40px;
		border-radius: 5px;
		border: 1px solid #0B3079;
		color: #0B3079;
		text-align: center;
		line-height: 40px;
	}

	.topNav_box {

		/* padding-bottom: 60px; */
		margin: 30px;
		background-color: #fff;
		border-radius: 10px;

		.topNav_tou {

			.topNav_tou_box {

				display: flex;
				flex-direction: column;
				align-items: center;
				width: 150px;
				/* height: 200px; */
				opacity: 1;
				border: 10px solid rgba(255, 255, 255, 0.15);
				margin: auto;
				border-radius: 75px;
				align-items: center;

				image {
					width: 150px;
					height: 150px;
					border-radius: 75px;
				}
			}
		}

		.topNav_name {
			position: relative;
			text-align: center;
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			background-color: #0B3079;
			padding: 3px 6px;
			border-radius: 20px;
			top: -20px;

			div {
				display: inline-block;
			}
		}

	}

	.avatar-uploader .avatar {
		width: 100px;
		height: 100px;
		border-radius: 50px;
		background-color: peachpuff;
		display: block;
	}

	.info {
		text-align: center;
		margin-top: 0px;

		.name {
			color: #333333;
			font-size: 20px;
		}

		.phone {
			color: #666666;
			font-size: 15px;
			margin-top: 15px;
			margin-bottom: 15px;
		}

		.number {
			font-size: 16px;
			color: #999999;
		}
	}

	.editinput {
		width: 330px;
	}
</style>