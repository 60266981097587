<template>
	<!-- <img alt="Vue logo" src="./assets/logo.png"> -->
	<!-- <home/> -->

	<!-- <loginview / > -->
	<router-view />
	<!-- <footerview></footerview> -->
</template>

<script>
	// import HelloWorld from './components/HelloWorld.vue'
	import './element-variables.scss'
	
	import home from './view/My/index.vue'
	
	


	export default {

		name: 'App',
		components: {
			
			home
		}
	}
</script>

<style>
	body {
		margin: 0;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-align: center; */
		color: #2c3e50;
		/* margin-top: 60px; */
	}
</style>
