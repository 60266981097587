import { createApp } from 'vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueLuckyCanvas from '@lucky-canvas/vue'

import store from './store/index';

import App from './App.vue'

const app = createApp(App)
app.use(router) //注册路由
app.use(store) //注册uvex
app.use(ElementPlus,{  locale: zhCn})
app.use(VueLuckyCanvas) //转盘

app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
