<template>
	<div>
		<div class='top'>
			<div class="left">
				<img style="width: 190px;height: 30px;" src="@/assets/homelogo.png" alt="" class="imgs">
			</div>
			<div class="content">
				<div class="right">
					<div style="display: flex;align-items: center;justify-items: center;margin-left: 40px;">
						<img v-if="infoData.headUrl" style="width: 40px;height: 40px;margin-right: 10px;border-radius: 20px;" :src="infoData.headUrl"
							alt="" />
							<img  style="width: 40px;height: 40px;margin-right: 10px;border-radius: 20px;" v-else  src="@/assets/touxiang.png" class="avatar" />
						<div>{{infoData.name}}</div>
					</div>
					<div style="display: flex;justify-content: space-around;margin-left:30px;">
						<div @click="tabclick(0)">
							个人信息
						</div>
						<div style="margin-left: 20px;margin-right: 20px;">
							|
						</div>
						<div @click="tabclick(1)">
							监考指南
						</div>
					</div>
					<div @click="loginOrout(2)" style="display: flex;justify-content: space-around;margin-left:30px;">
						<img style="width: 20px;height: 20px;margin-right: 10px;" src="../assets/out.png" alt="" />
						<div>退出登录</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog v-model="dialogVisible" title="监考指南" width="900" :before-close="handleClose">
			<div class="showmain" v-html="explainData.value"></div>
			<div class="btn" @click="dialogVisible = false"
				style="background-color:#0B3079;color: #fff;width: 130px;height: 40px;margin-left: auto;margin-right: auto;margin-bottom: 20px;margin-top: 40px;text-align: center;">
				知道了
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		userInfo,
		explain
	} from "@/api/index"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
		},
		// 接收父组件数据
		props: {
			showindex: String,
		},
		// 组件数据
		data() {
			return {
				showSearch: false,
				islogin: false,
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input: "",
				titles: [],
				seting: {},
				LanguageList: [],
				userinfo: {},
				infoData:{},
				explainData:{
					value:""
				}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {
		},
		mounted() {
			// window.addEventListener(
			// 	"loginOut",
			// 	this.loginshow
			// );
			// var info = localStorage.getItem("user");
			// console.log('info==', info);
			// this.userinfo = JSON.parse(info);
			// if (this.userinfo) {
			// 	if (this.userinfo.avater_str.length) {
			// 		this.usericon = this.userinfo.avater_str
			// 	}
			// }
			this.getdata()
			this.getexplain()
		},
		// 组件方法
		methods: {
			getexplain(){
				explain().then(res => {
					console.log(res)
					if(res.code == 0){
						this.explainData = res.data
					}
				})
			},
			loginOrout() {
				if (this.userinfo) {
					ElMessageBox.confirm(
							'您确定要退出登陆', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								confirmButtonClass: 'btnConfirm',
								center: true,
							}
						)
						.then(() => {
							console.log("退出")
							this.cancelOrder()
						})
						.catch(() => {})
				} else {
					this.$router.push({
						name: 'my'
					}) 
				}
			},
			cancelOrder() {
				localStorage.clear()
				ElMessage({
					message: '退出成功',
					type: 'success',
				})
				this.$router.push({
					name: 'login'
				})
			},
			getdata() {
				userInfo().then(res => {
					this.infoData =  res.data
					localStorage.setItem("user", JSON.stringify(res.data))
				})
			},
			loginshow() {
				this.islogin = true
			},
			seraclick() {
				this.showSearch = !this.showSearch
			},
			loginclick() {
				this.islogin = true
			},
			goLanguage(data, index) {
				for (let i in data) {
					data[i].show = false
				}
				data[index].show = true
			},
			searchclick() {
				this.$router.push({
					name: 'activities',
					query: {
						'search': this.input
					}
				})
			},
			tabclick(index) {
				switch (index) {
					case 0:
						this.$router.push({
							name: 'my'
						})
						break;
					case 1:
					this.dialogVisible = true
						break;
					case 2:
						this.$router.push({
							name: 'login'
						})
						break;
					case 3:
						this.$router.push({
							name: 'my'
						})
						break;
					case 4:
						this.$router.push({
							name: 'meeting'
						})
						break;
				}
			},
		},
	}
</script>
<style scoped lang='scss'>
	.top {
		background-color: #0B3079;
		padding: 25px;
		display: flex;
		min-width: 1000px;
		align-items: center;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 4px 10px 0px rgba(220, 220, 220, 0.3);
		.left {
			display: flex;
			align-items: center;
			.imgs {
				width: 230px;
				height: 87px;
			}
			.txt {
				font-size: 30px;
				color: #fff;
				font-weight: 400;
				margin-right: 30px;
			}
			.line {
				width: 0px;
				height: 32px;
				opacity: 1;
				border: 1px solid #FFFFFF;
				margin-right: 30px;
			}
		}
		.content {
			display: flex;
			align-items: center;
			.tap {
				cursor: pointer;
				display: flex;
				margin-right: 80px;
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 500;
				.name {
					padding: 8px 23px;
					color: #fff;
					background-color: #FF6A00;
					border-radius: 6px 6px 6px 6px;
					margin-left: 60px;
					font-size: 16px;
				}
				.name1 {
					padding: 8px 23px;
					color: #333333;
					background-color: #fff;
					border-radius: 6px 6px 6px 6px;
					margin-left: 60px;
					font-size: 16px;
				}
			}
			.search {
				height: 42px;
				margin-right: 40px;
			}
			.right {
				display: flex;
				align-items: center;
				justify-items: center;
				color: #FFFFFF;
				font-size: 16px;
			}
		}
	}
	.Tips {
		color: #141923;
		font-size: 20px;
		margin: 0 auto;
		width: 80%;
		text-align: center;
	}
	.topicon {
		width: 40px;
		height: 20px;
		position: relative;
		top: -22px;
		right: 455px;
	}
	.topserach {
		height: 83px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.searview {
		border: 1px solid #FF6A00;
		border: 5px;
		display: flex;
		line-height: 43px;
	}
	.codebtn {
		font-size: 14px;
		width: 92px;
		text-align: center;
		color: #fff;
		background: linear-gradient(90deg, #FF922B 0%, #FF6A00 100%);
	}
	::v-deep .el-button el-button--primary {
		background-color: #FF6A00;
	}
	.btn {
		width: 72px;
		height: 40px;
		border-radius: 20px;
		border: 1px solid #0B3079;
		color: #0B3079;
		text-align: center;
		line-height: 40px;
	}
</style>